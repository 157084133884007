import { Box, Heading, SimpleGrid, Text } from '@chakra-ui/react';
import version from 'release.current-version';

type FooterProps = {
	columns: JSX.Element[];
};

const Footer = ({ columns }: FooterProps): JSX.Element => {
	return (
		<>
			<SimpleGrid
				as="footer"
				columns={3}
				gap={4}
				my={4}
				p={4}
				borderRadius={4}
				bgColor={'gray.100'}
			>
				<Box
					p={4}
					borderRadius={4}
					backgroundColor="pink.100"
				>
					<Heading size="lg">Yoga... dat is gezond!</Heading>
					<Text my={2}>
						We are a community of people who share the same passion for
						exercising, practicing, learning and teaching yoga.
					</Text>

					<Heading size="md">Join our community</Heading>
					<Text my={2}>
						We would love to welcome you to join our journey in building the
						biggest online yoga resource.
					</Text>
				</Box>
				<Box
					p={4}
					borderRadius={4}
					backgroundColor="blue.50"
				>
					{columns?.[0]}
				</Box>
				<Box
					p={4}
					borderRadius={4}
					backgroundColor="blue.50"
				>
					{columns?.[1]}
				</Box>
			</SimpleGrid>
			<Text
				my={2}
				fontSize="xs"
				textAlign="right"
			>
				Copyright 2022, <a href="https://dasgezond.nl/">Dasgezond</a>, version{' '}
				{version}.
			</Text>
		</>
	);
};

export default Footer;
