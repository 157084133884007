import { Text, Box, Link, Tag, Heading, Flex, Wrap } from '@chakra-ui/react';

import { Playlist } from '../../prisma/utils/playlist';
import TagCloud from '../tag/cloud';

type PlaylistCardProps = {
	playlist: Playlist;
};

const PlaylistCard: React.FC<PlaylistCardProps> = ({ playlist }) => {
	return (
		<Box>
			<Heading
				size="md"
				mb={2}
			>
				<Link href={`/playlists/${playlist.id}/${playlist.slug}`}>
					{playlist.name}
				</Link>
			</Heading>

			<Text noOfLines={2}>{playlist.description}</Text>

			<Wrap
				spacing={2}
				my={2}
			>
				<Tag colorScheme="green">
					<Link href={`/media-types/${playlist.type.slug}`}>
						{playlist.type.name}
					</Link>
				</Tag>

				<Tag colorScheme="green">{playlist.level}</Tag>
			</Wrap>

			<Text>Categories</Text>
			<TagCloud
				tags={playlist.categories}
				hrefPrefix="/playlists/filter/categories"
				colorScheme="blue"
			/>

			<Text>Tags</Text>
			<TagCloud
				tags={playlist.tags}
				hrefPrefix="/playlists/filter/tags"
			/>
		</Box>
	);
};

export default PlaylistCard;
