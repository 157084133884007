import Footer from '@/components/footer';
import MediaTypeList from '@/components/media-type/list';
import UserCard from '@/components/user/card';
import { useRecentPlaylists } from '@/hooks/api';
import {
	fetchPopularMediaTypes,
	MediaTypeWithPlaylists,
} from '@/prisma/utils/media-type';
import {
	AspectRatio,
	Divider,
	Heading,
	Text,
	Flex,
	Avatar,
	Tag,
	Box,
	Container,
} from '@chakra-ui/react';
import { GetStaticProps } from 'next';
import Image from 'next/image';
import { QueryClient } from 'react-query';
import { dehydrate } from 'react-query/hydration';
import LandingPage from '../components/layout/landing-page';
import MediaGrid from '../components/media/grid';
import PlaylistGrid, { PlaylistItemsGrid } from '../components/playlist/grid';
import { fetchRecentMedia, MediaWithRelations } from '../prisma/utils/media';
import { fetchRecentPlaylists } from '../prisma/utils/playlist';
import { fetchRecentUsers } from '../prisma/utils/user';

type HomePageProps = {
	mediaTypes: MediaTypeWithPlaylists[];
	recentMedia: MediaWithRelations[];
	// recentPlaylists: any[];
};

const HomePage: React.FC<HomePageProps> = (props: HomePageProps) => {
	const { mediaTypes, recentMedia } = props;
	const { isLoading, data: recentPlaylists } = useRecentPlaylists();

	if (isLoading) {
		return <h1>Loading...</h1>;
	}

	return (
		<LandingPage
			title="Yoga! Dat is gezond."
			description="Best Yoga video's online!"
		>
			<Box
				mb={8}
				p={8}
				boxShadow="md"
				backgroundColor="blue.50"
				borderRadius="2xl"
			>
				<Heading
					size="md"
					mb={4}
				>
					Welcome, we need a title and some intro
				</Heading>
				<Text mb={4}>
					Eu do ex dolor aliqua nisi cillum magna aute anim elit. Cupidatat
					tempor incididunt ullamco amet aliqua est exercitation eu et sunt
					tempor velit. Lorem ex ipsum culpa ullamco aliquip ipsum ad minim duis
					labore voluptate sunt est. Voluptate ad nisi dolor laboris laborum
					magna.
				</Text>
				<Text>
					Labore pariatur sit officia consequat. Amet amet eiusmod ex sint
					eiusmod ad. Veniam est cupidatat excepteur do. Laboris dolore ullamco
					proident do nisi elit mollit aute nulla. Eu sit in qui occaecat. Nisi
					amet id aliquip consequat cupidatat reprehenderit nulla ipsum
					incididunt veniam.
				</Text>
			</Box>

			{/* <Heading size="lg">Types of yoga</Heading> */}
			<MediaTypeList mediaTypes={mediaTypes || []}>
				{(mediaType) => (
					<>
						<Heading size="sm">Popular {mediaType.name} playlists:</Heading>
						{mediaType.playlists.map((p) => (
							<Flex
								key={p.id}
								justifyContent="space-between"
								alignItems="center"
								my={4}
								p={3}
								boxShadow="md"
								backgroundColor="pink.50"
								borderRadius="xl"
							>
								<Box
									// flex={0}
									width="90px"
									height="60px"
									overflow="hidden"
									borderRadius="xl"
									backgroundColor="gray.100"
								>
									<Image
										src={mediaType.images[0].src}
										objectFit="cover"
										// layout="fill"
										width="90"
										height="60"
									/>
								</Box>

								<Heading
									flex={4}
									maxW="50%"
									m={2}
									size="md"
									noOfLines={1}
								>
									{p.name}
								</Heading>
								<Flex
									flex={1}
									alignItems="center"
									justifyContent="space-between"
									fontSize="sm"
								>
									<Tag
										colorScheme="green"
										size="sm"
										overflow="hidden"
									>
										{p.level}
									</Tag>
									<Flex
										alignItems="center"
										minWidth="80px"
										maxWidth="80px"
									>
										<Avatar
											name={p.owner.name || undefined}
											size="xs"
										/>
										<Text noOfLines={1}>
											&nbsp;
											{p.owner.name}
										</Text>
									</Flex>
								</Flex>
							</Flex>
						))}
						<PlaylistGrid playlists={mediaType.playlists}>
							{({ playlist }) => (
								<Text>{playlist._count?.interactions} interactions</Text>
							)}
						</PlaylistGrid>
					</>
				)}
			</MediaTypeList>
			<Heading size="lg">Recent playlists</Heading>
			<PlaylistGrid playlists={recentPlaylists || []}>
				{({ playlist }) => (
					<>
						<PlaylistItemsGrid items={playlist.items || []} />
						<Text>{playlist._count?.items} items</Text>
						<Text>{playlist._count?.interactions} interactions</Text>
						<Text>Curated by</Text>
						<UserCard user={playlist.owner} />
					</>
				)}
			</PlaylistGrid>
			<Divider marginY="4" />
			<Heading size="lg">Recent videos</Heading>
			<MediaGrid media={recentMedia} />

			<Footer columns={[]} />
		</LandingPage>
	);
};

export const getStaticProps: GetStaticProps = async ({ params }) => {
	const queryClient = new QueryClient();

	const mediaTypes = await fetchPopularMediaTypes();
	const recentUsers = await fetchRecentUsers();
	const recentMedia = await fetchRecentMedia();

	await queryClient.prefetchQuery(['playlists', { recent: true }], () =>
		fetchRecentPlaylists()
	);

	return {
		revalidate: 5,
		props: {
			mediaTypes,
			recentUsers,
			recentMedia,
			dehydratedState: dehydrate(queryClient),
		},
	};
};

export default HomePage;
