import { MediaType } from '@/prisma/utils/media-type';
import {
	AspectRatio,
	Box,
	Heading,
	List,
	ListItem,
	SimpleGrid,
	Text,
} from '@chakra-ui/react';
import Image from 'next/image';
import React from 'react';

interface MediaTypeListProps<T> {
	mediaTypes: T[];
	children?: (mediaType: T) => JSX.Element | undefined;
}

const MediaTypeList = <T extends MediaType>({
	mediaTypes,
	children,
}: MediaTypeListProps<T>): JSX.Element => {
	const list = mediaTypes.map((item) => {
		const rnd = Math.floor(Math.random() * item.images.length);

		return (
			<Box
				key={item.id}
				// padding="4"
				// bgColor="green.50"
				borderRadius="2xl"
			>
				<AspectRatio
					ratio={4 / 2}
					borderRadius="2xl"
					overflow="hidden"
					backgroundColor="gray.100"
				>
					<>
						{item.images.length > 0 && (
							<Image
								src={item.images?.[rnd].src}
								objectFit="cover"
								layout="fill"
								// layout="responsive"
								// width="640"
								// height="320"
							/>
						)}
					</>
				</AspectRatio>

				<Box
					px={3}
					py={4}
				>
					<Heading size="lg">{item.name}</Heading>
					<Text>{item.description}</Text>
				</Box>
				<Box p={3}>{children && children(item)}</Box>
			</Box>
		);
	});

	return (
		<SimpleGrid
			columns={[1, null, null, null, 2, 2]}
			spacing={6}
		>
			{list}
		</SimpleGrid>
	);
};

MediaTypeList.defaultProps = {
	children: () => undefined,
};

export default MediaTypeList;
