import { Box, Button, Flex, Heading, HStack, Text } from '@chakra-ui/react';
import { useSession } from 'next-auth/client';
import { Media } from '../../prisma/utils/media';
import TagCloud from '../tag/cloud';
import { MediaButtonAddToPlaylist } from './button/add-to-playlist';
import MediaPlayer from './player';
import MediaThumbnail from './thumbnail';

type MediaPreviewProps = {
	media: Media;
};

const MediaPreview: React.FC<MediaPreviewProps> = ({ media }) => {
	const { source } = media;
	// const sourceUuid = 'r7xsYgTeM2Q';
	// https://www.youtube.com/oembed?url=https://www.youtube.com/watch?v=UEEsdXn8oG8&format=json
	const [session, loading] = useSession();

	return (
		<Box>
			<Heading size="md">{media.name}</Heading>
			<Flex justifyContent="space-between">
				<Text>{source.author}</Text>
				<Text>{media.type.name}</Text>
				<Text>{media.level}</Text>
				{/* <Text>Likes {media._count.interactions}</Text> */}
				{/* <Text>Playlists {media._count.playlists}</Text> */}
			</Flex>
			{/* <MediaPlayer sourceUuid={source.providerUuid} /> */}
			<MediaThumbnail media={media} />
			<HStack
				as="nav"
				padding={2}
				backgroundColor="green.100"
			>
				<Button
					disabled={!session}
					colorScheme="pink"
					size="sm"
				>
					Love
				</Button>
				<Button
					disabled={!session}
					colorScheme="pink"
					size="sm"
				>
					Favorite
				</Button>
				<MediaButtonAddToPlaylist media={media} />
			</HStack>
			<Box>
				<Text>Categories</Text>
				<TagCloud
					tags={media.categories}
					hrefPrefix="/media/filter/categories"
					colorScheme="blue"
				/>
			</Box>
			<Box>
				<Text>Tags</Text>
				<TagCloud
					tags={media.tags}
					hrefPrefix="/media/filter/tags"
					colorScheme="pink"
				/>
			</Box>
		</Box>
	);
};

export default MediaPreview;
