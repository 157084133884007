import { useCreatePlaylistItems, useUserPlaylists } from '@/hooks/api';
import { Media } from '@/prisma/utils/media';
import { Playlist } from '@/prisma/utils/playlist';
import {
	Button,
	Menu,
	MenuButton,
	MenuItem,
	MenuList,
	useToast,
} from '@chakra-ui/react';
import { useSession } from 'next-auth/client';

export const MediaButtonAddToPlaylist: React.FC<{ media: Media }> = ({
	media,
}) => {
	const toast = useToast();
	const [session, loading] = useSession();
	const { data: userPlaylists, isLoading } = useUserPlaylists(session?.user);
	const createPlaylistItems = useCreatePlaylistItems();

	const handleAddToPlaylistClick = (playlist: Playlist) => {
		createPlaylistItems.mutate(
			{
				playlist,
				data: {
					items: [
						{
							mediaId: media.id,
							order: 0,
						},
					],
				},
			},
			{
				onSuccess: () => {
					toast({
						title: 'Success!',
						description: `Media "${media.name}" was added to your playlist "${playlist.name}".`,
						status: 'success',
						isClosable: true,
					});
				},
			}
		);
	};

	return (
		<Menu>
			<MenuButton
				as={Button}
				disabled={!session}
				colorScheme="pink"
				size="sm"
				// rightIcon={<ChevronDownIcon />}
			>
				Add to playlist
			</MenuButton>
			{userPlaylists && (
				<MenuList>
					{userPlaylists.map((playlist: Playlist) => (
						<MenuItem
							key={playlist.id}
							onClick={() => handleAddToPlaylistClick(playlist)}
						>
							{playlist.name}
						</MenuItem>
					))}
				</MenuList>
			)}
		</Menu>
	);
};
