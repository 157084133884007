import { Playlist, PlaylistItem } from '@/prisma/utils/playlist';
import { Box, SimpleGrid } from '@chakra-ui/layout';
import React from 'react';
import MediaThumbnail from '../media/thumbnail';
import PlaylistCard from './card';

interface PlaylistGridProps<T> {
	playlists: T[];
	controls?: ({ playlist }: { playlist: T }) => JSX.Element | undefined;
	children?: ({ playlist }: { playlist: T }) => JSX.Element | undefined;
}

const defaultProps = {
	children: () => undefined,
	controls: () => undefined,
};

const PlaylistGrid = <T extends Playlist>({
	playlists = [],
	controls,
	children,
}: PlaylistGridProps<T>): JSX.Element => {
	const items = playlists.map((item) => {
		return (
			<Box
				key={item.id}
				as="li"
				padding={3}
				bgColor="blue.50"
				borderRadius="xl"
				boxShadow="md"
			>
				<Box>
					<PlaylistCard playlist={item} />
					{controls && controls({ playlist: item })}
					{children && children({ playlist: item })}
				</Box>
			</Box>
		);
	});

	return (
		<SimpleGrid
			as="ul"
			listStyleType="none"
			spacing={6}
			// columns={[1, null, 2, 3, 4]}
			columns={[1, null, 2, 2, 2, 2]}
		>
			{items}
		</SimpleGrid>
	);
};

PlaylistGrid.defaultProps = defaultProps;

type PlaylistItemsGridProps = {
	items: PlaylistItem[];
};

export const PlaylistItemsGrid: React.FC<PlaylistItemsGridProps> = ({
	items,
}) => {
	return (
		<SimpleGrid
			columns={[5]}
			spacing={[1]}
		>
			{items.map((item) => (
				<MediaThumbnail
					key={item.id}
					media={item.media}
				/>
			))}
		</SimpleGrid>
	);
};

export default PlaylistGrid;
